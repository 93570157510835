import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p className="poem" style={{marginTop: "1.5em"}}>
          Bleibt nie lang an einem Ort,<br/>
          Drehst Du Dich um, ist er schon fort.<br/>
          Doch wählst Du meine Nummer an,<br/>
          Ich ihn für Dich fangen kann.
    </p>
    <p className="poem">
          Was Du auch suchst – Frag’ einfach nach;<br/>
          Die Fantasie hat hier ein Dach!
    </p>
    <p>
          <u><i>Im Klartext:</i></u><br />
          FANTASIE, der mystische Laden, lässt (fast) keine Wünsche offen.<br /> 
          Er ist im weiteren Sinne eine Erscheinung, welche durch die Gegend reist und
          auch zu Ihnen kommen kann.<br />
          Anonymität ist dabei die oberste Direktive.<br />
          Er kann Ihnen sogar helfen zwischen
          weltlichen und geistlichen (und sphärischen) Bedürfnissen zu unterscheiden.
          Somit können Sie alle notwendigen Mittel erhalten, um wunschlos glücklich werden zu können.
    </p>
    <p>
          Um räumlich flexibel bleiben zu können, findet die Kontaktaufnahme in der Regel telefonisch oder per E-Mail statt.<br />
          Ich freue mich über jeden Kontakt und wünsche allen Bekannten und Unbekannten eine schöne Zeit,<br />
          <br />
          A. Schneider
    </p>
  </Layout>
              )

              export default IndexPage
